import React, {useState} from 'react';
import './App.css';
import Results from "./Results";
import Questionnaire from "./Questionnaire";
import {Container} from "react-bootstrap";
import {EducationLevel, FormState, InTheJobTraining, JobRarity, PayScale} from "./FormState";

function App() {
    const [form, setForm] = useState<FormState>({
        education: EducationLevel.None,
        training: InTheJobTraining.None,
        rarity: JobRarity.FiftyThousandOrMore,
        pay: PayScale.UpperMiddleClass,
        interests: "I like ponies!",
        image: null,
    })

    return (<Container>
        <Questionnaire form={form} setForm={setForm}/>
        <Results/>
    </Container>);
}

export default App;
