import React, {FC, useState} from 'react';
import {Button, Container, Form} from 'react-bootstrap';
import {
    EducationLevel,
    EducationMap,
    FormState,
    InTheJobTraining,
    InTheJobTrainingMap,
    JobRarity,
    JobRarityMap,
    PayScale,
    PayScaleMap
} from "./FormState";

type QuestionnaireParams = {
    form: FormState, setForm: (formState: FormState) => void,
}

const Questionnaire: FC<QuestionnaireParams> = ({form, setForm}) => {
    const [imagePreview, setImagePreview] = useState(form.image || '');

    const handleImageUpload = (e: any) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                const base64Image = reader.result as string;
                setImagePreview(base64Image);
                update({image: base64Image});
            };
            reader.readAsDataURL(file);
        }
    };

    const handleTakePhoto = async () => {
        try {
            if ('mediaDevices' in navigator && navigator.mediaDevices.getUserMedia) {
                const stream = await navigator.mediaDevices.getUserMedia({video: true});
                const video = document.createElement('video');
                video.srcObject = stream;
                video.play();

                const canvas = document.createElement('canvas');
                video.addEventListener('loadeddata', () => {
                    canvas.width = video.videoWidth;
                    canvas.height = video.videoHeight;
                    const context = canvas.getContext('2d');
                    context!.drawImage(video, 0, 0, canvas.width, canvas.height);
                    const base64Image = canvas.toDataURL('image/png');
                    setImagePreview(base64Image);
                    update({image: base64Image});
                    stream.getTracks().forEach(track => track.stop());
                });
            } else {
                alert('Your browser does not support camera access.');
            }
        } catch (error: any) {
            alert('Error accessing camera: ' + error.message);
        }
    };

    const handleSubmit = async (evt: React.FormEvent<HTMLFormElement>) => {
        evt.preventDefault();

        const formData = {
            image: form.image,
            interests: form.interests,
            education: form.education,
            training: form.training,
            rarity: form.rarity,
            pay: form.pay,
        };

        console.log('Submitting form data:', formData);

        try {
            const response = await fetch('/bin/jobs.fcgi', {
                method: 'POST', headers: {
                    'Content-Type': 'application/json',
                }, body: JSON.stringify(formData),
            });

            console.log('Response:', response.body);

            if (response.ok) {
                alert('Form submitted successfully!');
            } else {
                alert('Failed to submit form. Status: ' + response.statusText);
            }
        } catch (error:any) {
            console.error('Error submitting form:', error);
            alert('Error submitting form: ' + error.message);
        }
    };

    const update = (changes: Partial<FormState>) => {
        setForm({...form, ...changes});
    };

    return (<Container className="questionnaire">
        <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-3" controlId="image">
                <Form.Label>Photo</Form.Label>
                <div>
                    <Button variant="primary" onClick={() => document.getElementById('photoUpload')?.click()}>
                        Pick a photo
                    </Button>
                    <Button variant="secondary" onClick={handleTakePhoto}>
                        Take a photo
                    </Button>
                    <input type="file" id="photoUpload" style={{display: 'none'}} onChange={handleImageUpload}/>
                </div>
                <div style={{marginTop: '10px'}}>
                    {imagePreview ? (
                        <img src={imagePreview} alt="Preview" style={{maxWidth: '200px', maxHeight: '200px'}}/>) : (
                        <div style={{width: '200px', height: '200px', backgroundColor: 'gray'}}/>)}
                </div>
            </Form.Group>
            <Form.Group className="mb-3" controlId="interests">
                <Form.Label>Hobbies and Interests</Form.Label>
                <Form.Control as="textarea" placeholder="What are your hobbies and interests?" onChange={(e) => {
                    update({interests: e.target.value});
                }} value={form.interests}/>
            </Form.Group>
            <Form.Group className="mb-3">
                <Form.Label htmlFor="education">How much do you like school?</Form.Label>
                <Form.Select id="education" onChange={(e) => {
                    const selectedLevel = e.target.value as EducationLevel;
                    update({education: selectedLevel});
                }} value={form.education}>
                    {Object.values(EducationLevel)
                        .map(el => <option value={el}>{EducationMap[el]}</option>)}
                </Form.Select>
            </Form.Group>
            <Form.Group className="mb-3">
                <Form.Label htmlFor="training">Do you like on the job training?</Form.Label>
                <Form.Select id="training" onChange={(e) => {
                    const selectedTraining = e.target.value as InTheJobTraining;
                    update({training: selectedTraining});
                }} value={form.training}>
                    {Object.values(InTheJobTraining)
                        .map(el => <option value={el}>{InTheJobTrainingMap[el]}</option>)}
                </Form.Select>
            </Form.Group>
            <Form.Group className="mb-3">
                <Form.Label htmlFor="rarity">How unique (but also hard to get) do you want your jobs?</Form.Label>
                <Form.Select id="rarity" onChange={(e) => {
                    const selectedRarity = e.target.value as JobRarity;
                    update({rarity: selectedRarity});
                }} value={form.rarity}>
                    {Object.values(JobRarity)
                        .map(el => <option value={el}>{JobRarityMap[el]}</option>)}
                </Form.Select>
            </Form.Group>
            <Form.Group className="mb-3">
                <Form.Label htmlFor="pay">Dat money, how much?</Form.Label>
                <Form.Select id="pay" onChange={(e) => {
                    const pay = e.target.value as PayScale;
                    update({pay: pay});
                }} value={form.pay}>
                    {Object.values(PayScale)
                        .map(el => <option value={el}>{PayScaleMap[el]}</option>)}
                </Form.Select>
            </Form.Group>

            <Button variant="primary" type="submit">
                Submit
            </Button>
        </Form>
    </Container>);
};

export default Questionnaire;
