import {useState} from "react";

export enum EducationLevel {
    None = "No formal educational credential",
    HighSchool = "High school diploma or equivalent",
    SomeCollege = "Some college, no degree",
    Bachelors = "Bachelor's degree",
    Masters = "Master's degree",
    Doctor = "Doctoral or professional degree"
}

export const EducationMap: { [key in EducationLevel]: string } = {
    [EducationLevel.None]: "hate school",
    [EducationLevel.HighSchool]: "graduated high school",
    [EducationLevel.SomeCollege]: "sorta like the idea of college",
    [EducationLevel.Bachelors]: "like to learn!",
    [EducationLevel.Masters]: "really like school",
    [EducationLevel.Doctor]: "want to be in school forever!"
};

export enum InTheJobTraining {
    None = "None",
    ShortTerm = "Short-term on-the-job training",
    ModerateTerm = "Moderate-term on-the-job training",
    LongTerm = "Long-term on-the-job training",
    Apprentice = "Apprenticeship",
    Internship = "Internship/residency",
}

export const InTheJobTrainingMap: { [key in InTheJobTraining]: string } = {
    [InTheJobTraining.None]: "hate job training",
    [InTheJobTraining.ShortTerm]: "some training ok",
    [InTheJobTraining.ModerateTerm]: "more training is good",
    [InTheJobTraining.LongTerm]: "take my time training",
    [InTheJobTraining.Apprentice]: "learn from an expert for a while",
    [InTheJobTraining.Internship]: "love learning from experts"
};


export enum JobRarity {
    Declining = "Declining",
    ZeroToThousand = "0 to 999",
    ThousandToFiveThousand = "1,000 to 4,999",
    FiveThousandToTenThousand = "5,000 to 9,999",
    TenThousandToFiftyThousand = "10,000 to 49,999",
    FiftyThousandOrMore = "50,000 or more",
}

export const JobRarityMap: { [key in JobRarity]: string } = {
    [JobRarity.Declining]: "short term, hard to find jobs",
    [JobRarity.ZeroToThousand]: "really rare, hard to get",
    [JobRarity.ThousandToFiveThousand]: "rare job, few people can do",
    [JobRarity.FiveThousandToTenThousand]: "rarish jobs, many people can do",
    [JobRarity.TenThousandToFiftyThousand]: "sorta rare jobs",
    [JobRarity.FiftyThousandOrMore]: "lots of opportunity"
};


export enum PayScale {
    Poverty = "Less than $30,000",
    Low = "$30,000 to $39,999",
    Median = "$40,000 to $59,999",
    MiddleClass = "$60,000 to $79,999",
    UpperMiddleClass = "$80,000 or more",
}

export const PayScaleMap: { [key in PayScale]: string } = {
    [PayScale.Poverty]: "always broke",
    [PayScale.Low]: "live with family and friends",
    [PayScale.Median]: "live alone",
    [PayScale.MiddleClass]: "live alone and go on vacations",
    [PayScale.UpperMiddleClass]: "plenty of disposable income",
};


export interface FormState {
    education: EducationLevel,
    training: InTheJobTraining,
    rarity: JobRarity,
    pay: PayScale,
    interests: string,
    image: string|null,
}